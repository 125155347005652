export const LARGE_PRODUCT_ACTION_BUTTON_VIEWONMAP_FRAGMENT = `
  fragment largeProductActionButtonViewonmapFragment on LargeProduct {
    uid
    typename: __typename
    editionMappings {
     editionLargeProduct {
      uid
      id
      name
      booths {
        uid
        schemaCode
        exhibitHall {
          id
        }
        geozone {
          uid
          id
          name
        }
      }
     }
    }
  }
`;
